

<script>
export default {
  name: 'StatusFail'
}
</script>
<template>
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M35 0C15.6719 0 0 15.6719 0 35C0 54.3281 15.6719 70 35 70C54.3281 70 70 54.3281 70 35C70 15.6719 54.3281 0 35 0ZM47.9219 48.2969L42.7656 48.2734L35 39.0156L27.2422 48.2656L22.0781 48.2891C21.7344 48.2891 21.4531 48.0156 21.4531 47.6641C21.4531 47.5156 21.5078 47.375 21.6016 47.2578L31.7656 35.1484L21.6016 23.0469C21.5072 22.9324 21.4548 22.789 21.4531 22.6406C21.4531 22.2969 21.7344 22.0156 22.0781 22.0156L27.2422 22.0391L35 31.2969L42.7578 22.0469L47.9141 22.0234C48.2578 22.0234 48.5391 22.2969 48.5391 22.6484C48.5391 22.7969 48.4844 22.9375 48.3906 23.0547L38.2422 35.1562L48.3984 47.2656C48.4922 47.3828 48.5469 47.5234 48.5469 47.6719C48.5469 48.0156 48.2656 48.2969 47.9219 48.2969Z" fill="#FF3200"/>
  </svg>
</template>