<script>
export default {
  name: 'NVMe',
};
</script>

<template>
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0154 0.769137C12.0154 0.34606 11.6692 -9.39702e-05 11.2462 -9.39517e-05L1.53846 -9.35273e-05C0.692307 -9.34903e-05 -6.42221e-07 0.692215 -6.05235e-07 1.53837L-6.72483e-08 13.8461C-3.02617e-08 14.6922 0.692308 15.3845 1.53846 15.3845L11.2462 15.3845C11.6692 15.3845 12.0154 15.0384 12.0154 14.6153L12.0154 13.4614L12.6077 13.4614L12.6077 14.6153C12.6077 15.0384 12.9538 15.3845 13.3769 15.3845L16.9231 15.3845L20 15.3845L20 13.8461L20 9.84606L20 8.3076L18.4615 8.3076L18.4615 9.84606L20 9.84606L20 8.3076L18.4615 8.3076L18.4615 7.07683L20 7.07683L20 5.53837L18.4615 5.53837L18.4615 4.3076L20 4.3076L20 2.76914L20 -9.43343e-05L16.9231 -9.41998e-05L13.3769 -9.40448e-05C12.9538 -9.40263e-05 12.6077 0.34606 12.6077 0.769137L12.6077 1.92298L12.0154 1.92298L12.0154 0.769137ZM14.1462 1.92298L14.1462 1.53837L18.4615 1.53837L18.4615 2.76914L16.9231 2.76914L16.9231 9.84606L18.4615 9.84606L18.4615 13.8461L14.1462 13.8461L14.1462 13.4614C14.1462 12.6153 13.4538 11.923 12.6077 11.923L12.0154 11.923C11.1692 11.923 10.4769 12.6153 10.4769 13.4614L10.4769 13.8461L1.53846 13.8461L1.53846 1.53837L10.4769 1.53837L10.4769 1.92298C10.4769 2.76914 11.1692 3.46144 12.0154 3.46144L12.6077 3.46144C13.4538 3.46144 14.1462 2.76914 14.1462 1.92298Z"
      fill="#FF3200"
    />
  </svg>
</template>
