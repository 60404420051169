<script lang="ts">
import { defineComponent } from 'vue';
import packages from '~/frontend/packages';
import ButtonGhost from '~/frontend/components/Buttons/ButtonGhost.vue';
import ButtonLink from '~/frontend/components/Buttons/ButtonLink.vue';
import Payment from '~/frontend/components/Payment.vue';

import type { Locales } from '~/frontend/types';
import Enums from '~/frontend/enums';
import PaymentResult from '~/frontend/components/PaymentResult.vue';

export default defineComponent({
  name: 'App',
  components: {
    ButtonGhost,
    ButtonLink,
    PaymentResult
  },
  computed: {
    currentLocalType() {
      return window.currentLocal.type;
    },
    currentLocalData() {
      return window.currentLocal.data;
    },
    payment() {
      return Payment;
    },
    currentRouteName() {
      return this.$route.name;
    },
    docs(): Locales['data']['footer']['docs'] {
      return this.currentLocalData.footer.docs;
    },
    routeNames() {
      return Enums.RouteNames;
    }
  },
  data() {
    return {
      staticClass: 'app',
      packages,
    };
  },
});
</script>

<template>
  <div :class="staticClass">
    <div :class="staticClass + '__wrapper'">
      <header :class="'header'">
        <a :class="'header__logo-link'" href="/">
          <img :class="'header__logo'" width="220" height="32" src="./images/sph-logo.svg" />
        </a>
        <div :class="'header__buttons'">
          <div :class="'header__button'">
            <router-link tabindex="-1" to="/payment">
              <ButtonGhost
                :active="currentRouteName === routeNames.payment || currentRouteName === routeNames.status"
                :text="currentLocalData.header.payment"
              />
            </router-link>
          </div>
          <div v-if="currentLocalType !== 'en'" :class="'header__button'">
            <router-link tabindex="-1" to="/docs">
              <ButtonGhost
                :active="currentRouteName === routeNames.docs"
                :text="currentLocalData.header.docs"
              />
            </router-link>
          </div>
          <div :class="'header__button'">
            <router-link tabindex="-1" to="/">
              <ButtonGhost
                :active="currentRouteName === routeNames.hosting"
                :text="currentLocalData.header.hosting"
              />
            </router-link>
          </div>
        </div>
      </header>

      <router-view></router-view>

      <footer :class="'footer'">
        <div :class="'footer__wrapper'">
          <div :class="'footer__address'">
            <a :class="'footer__logo-link'" href="/">
              <img :class="'footer__logo'" width="170" height="32" src="./images/sph-logo.svg" />
            </a>
            <address :class="'footer__address'">{{ currentLocalData.footer.address }}</address>
          </div>

          <div :class="'footer__links'">
            <a :class="'footer__mail'" href="mailto:admin@sprinthost.com">admin@sprinthost.com</a>

            <template v-if="currentLocalType === 'en'">
              <div :class="'footer__button'">
                <ButtonLink
                  :href="docs.termsOfService.link"
                  :text="docs.termsOfService.name"
                  :target="'_blank'"
                />
              </div>
              <div :class="'footer__button'">
                <ButtonLink
                  :href="docs.privacyPolicy.link"
                  :text="docs.privacyPolicy.name"
                  :target="'_blank'"
                />
              </div>
            </template>

            <template v-else>
              <div :class="'footer__button'">
                <ButtonLink
                  :href="'/assets/oferta.pdf'"
                  :text="'Договор оферты'"
                  :target="'_blank'"
                />
              </div>
              <div :class="'footer__button'">
                <ButtonLink
                  :href="'/assets/pd.pdf'"
                  :text="'Политика конфиденциальности'"
                  :target="'_blank'"
                />
              </div>
              <div :class="'footer__button'">
                <ButtonLink
                  :href="'/assets/refund_policy.pdf'"
                  :text="'Политика возврата средств'"
                  :target="'_blank'"
                />
              </div>
            </template>
          </div>
        </div>

        <div :class="'status'">
          <div :class="'status__item'">
            <span :class="'status__title'">{{ currentLocalData.footer.status }}</span>
            <span :class="'status__value'">All systems normal</span>
          </div>
          <div :class="'status__item'">
            <span :class="'status__title'">IPv6</span>
            <span :class="'status__value'">Ready</span>
          </div>
          <div :class="'status__item'">
            <span :class="'status__title'">{{ currentLocalData.footer.support }}</span>
            <span :class="'status__value'">ChatGPT</span>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<style lang="scss">
@import '@dev-projects/uikit/dist/assets/index.css';
</style>
