<script>
import IconTemplate from '~/frontend/components/icons/IconTemplate.vue';
export default {
  name: 'ButtonLink',
  components: {
    IconTemplate,
  },
  methods: {
    callbackClick() {
      this.focused = false;
      this.$emit('click');
    },
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  data() {
    return {
      staticClass: 'button-link',
      focused: false,
    };
  },
};
</script>

<template>
  <a
    :class="[staticClass, { focused: focused }, { active: active }, { disabled: disabled }]"
    @click="callbackClick($event)"
    @keydown.space="callbackClick($event)"
    @keydown.tab="focused = true"
    @blur="focused = false"
    :href="href"
    :target="target"
  >
    {{ text }}

    <IconTemplate :class="`${staticClass}__icon`" :icon-name="'OpenLink'" />
  </a>
</template>
