<script>
export default {
  name: 'ButtonGhost',
  methods: {
    callbackClick() {
      this.focused = false;
      this.$emit('click');
    },
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      staticClass: 'button-ghost',
      focused: false,
    };
  },
};
</script>

<template>
  <button
    :class="[staticClass, { focused: focused }, { active: active }]"
    @click="callbackClick($event)"
    @keydown.space="callbackClick($event)"
    @keydown.tab="focused = true"
    @blur="focused = false"
    :tabindex="active || disabled ? -1 : tabindex"
  >
    {{ text }}
  </button>
</template>
