<script>
import IconTemplate from '~/frontend/components/icons/IconTemplate.vue';
import ButtonMain from '~/frontend/components/Buttons/ButtonMain.vue';
import Label from '~/frontend/components/Elements/Label.vue';
import Formatters from '@dev-projects/frontend-utils/dist/Helpers/Formatters';
import Enums from '~/frontend/enums';
import { getPaymentResultData } from '~/frontend/actions';

export default {
  components: {
    IconTemplate, ButtonMain, Label
  },
  methods: {
    goBack() {
      this.$router.push('/payment');
    },
    getData() {
      const sessionId = this.$route.query.session_id
      if(!sessionId) return;

      getPaymentResultData(sessionId).then((data)=> {
        if(data) {
          this.amount = Number(data.amount);
          this.bonus = Number(data.bonus);
          this.bonusDomains = Number(data.bonus_domains);
        }
      })
    }
  },
  computed: {
    currentLocalType() {
      return window.currentLocal.type;
    },
    currentLocalData() {
      return window.currentLocal.data;
    },
    currentPage() {
      return this.currentLocalData.pages.paymentResult;
    },
    generalInfo() {
      return this.currentLocalData.generalInfo;
    },
    currentStatus() {
      return this.$route.params.status;
    },
    paymentDetails() {
      let formattedBonus = parseFloat(this.Formatters.getNormalizedPrice(this.bonus).toFixed(2));
      let formattedAmount = parseFloat(this.Formatters.getNormalizedPrice(this.amount).toFixed(2));

      return {
        amount: {
          show: !!(this.amount),
          text: `${this.generalInfo.currency}${formattedAmount}`,
          color: 'grey'
        },
        bonusDomains: {
          show: !!(this.bonusDomains),
          text: `${this.Formatters.amountWords(this.bonusDomains, this.currentPage.success.details.domainWord, this.bonusDomains !== 1)}&nbsp;${this.currentPage.success.details.bonusDomains}`,
          color: 'green'
        },
        bonus: {
          show: !!(this.bonus),
          text: `${formattedBonus}&nbsp;${this.Formatters.amountWords(this.bonus, this.currentPage.success.details.sprintcoinsWord)}`,
          color: 'green'
        }
      }
    },
  },
  mounted() {
    if(this.currentStatus === this.Enums.Status.success) {
      this.getData();
    }
  },
  data() {
    return {
      staticClass: 'payment-result',
      Formatters,
      sessionId: null,
      Enums,
      bonusDomains: null,
      amount: null,
      bonus: null,
    }
  }
}
</script>

<template>
<div :id="staticClass">
  <div :class="`${staticClass}`">
    <template v-if="currentStatus === Enums.Status.success">
      <div :class="`${staticClass}__icon`">
        <IconTemplate
          :icon-name="'StatusOk'"
        />
      </div>
      <p :class="`${staticClass}__title`">{{currentPage.success.title}}</p>
      <p :class="`${staticClass}__descr`" v-html="`${currentPage.success.descr}`"></p>
      <div :class="`${staticClass}__labels`">
        <template v-for="(d, idx) in Object.values(paymentDetails)" >
          <div v-if="d.show" :class="`${staticClass}__label`" :key="idx">
            <span :class="`${staticClass}__plus`" 
            v-if="idx !== 0">
              +
            </span>
            <Label
              :text="d.text"
              :color="d.color"
            />
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div :class="`${staticClass}__icon`">
        <IconTemplate
          :icon-name="'StatusFail'"
        />
      </div>
      <p :class="`${staticClass}__title`">{{currentPage.fail.title}}</p>
      <p style="margin-bottom: 40px" :class="`${staticClass}__descr`" v-html="currentPage.fail.descr"></p>
    </template>
    <div :class="`${staticClass}__button`">
      <ButtonMain
        @click="goBack()"
        :text="currentPage.button"
      />
    </div>
  </div>
</div>
</template>
