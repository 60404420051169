import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import VueRouter from 'vue-router';

import Hosting from '~/frontend/components/Hosting.vue';
import Payment from '~/frontend/components/Payment.vue';
import PaymentResult from '~/frontend/components/PaymentResult.vue';
import Enums from '~/frontend/enums'

import en from '~/frontend/localies/en';
import App from './App.vue';

Vue.use(VueRouter);
Vue.use(vClickOutside);

const routes = [
  { path: '/', name: Enums.RouteNames.hosting, component: Hosting },
  { path: '/payment', name: Enums.RouteNames.payment, component: Payment},
  { path: '/payment/status/:status', name: Enums.RouteNames.status, component: PaymentResult, props: true},
  // { path: '/docs', name: 'docs', component: Documents },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

window.currentLocal = { type: 'en', data: en };

new Vue({
  el: '#app-vue',
  router,
  render(h) {
    return h(App);
  },
});
