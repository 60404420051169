<script>
import PackageCard from '~/frontend/components/PackageCard.vue';
import IconTemplate from '~/frontend/components/icons/IconTemplate.vue';
import packages from '~/frontend/packages';
import ModalContact from './ModalContact.vue';

export default {
  name: 'Hosting',
  components: {
    PackageCard,
    IconTemplate,
    ModalContact,
  },
  computed: {
    currentLocalType() {
      return window.currentLocal.type;
    },
    currentLocalData() {
      return window.currentLocal.data;
    },
    packages() {
      return this.currentLocalData.packages;
    },
  },
  methods: {
    openModal(tariff) {
      this.showModal = true;
      this.currentTariff = tariff;
    },
    closeModal() {
      this.showModal = false;
      this.currentTariff = '';
    },
  },
  data() {
    return {
      staticClass: 'hosting',

      showModal: false,
      currentTariff: '',
    };
  },
};
</script>

<template>
  <div :class="staticClass">
    <section :class="'head'">
      <h1 :class="'head__title'">{{ currentLocalData.pages.hosting.title }}</h1>
      <p :class="'head__note'">{{ currentLocalData.pages.hosting.desc }}</p>
    </section>

    <section :class="'packages'">
      <template v-for="tariff in packages">
        <PackageCard @openModal="openModal(tariff.title)" :tariff="tariff" />
      </template>
    </section>

    <ModalContact :show-modal="showModal" @close="closeModal()" :tariff="currentTariff" />
  </div>
</template>
