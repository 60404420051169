<script>
import InputText from '@dev-projects/uikit/components/v4/Fields/TextInputs/InputText.vue';
import ButtonMain from '~/frontend/components/Buttons/ButtonMain.vue';
import { sendEmail } from '~/frontend/actions';

export default {
  name: 'Modal',
  components: {
    InputText,
    ButtonMain,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    tariff: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentLocalType() {
      return window.currentLocal.type;
    },
    currentLocalData() {
      return window.currentLocal.data;
    },
  },
  methods: {
    send() {
      this.validate();
      if (this.error.length > 0) return;

      this.isLoading = true;

      const data = {
        email: this.email,
        tariff: this.tariff,
      };

      sendEmail(data)
        .then(res => {
          if (res.status === 1) {
            this.email = '';
            this.$emit('close');

            return true;
          }

          this.error = res;

          return false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    matchEmail() {
      this.emailMatched = !/(.+)@(.+)\.(.+)/.test(this.$data.email);
    },

    validate() {
      const emailRegex = /(.+)@(.+)\.(.+)/;

      if (!emailRegex.test(this.email)) {
        this.error = 'Неверная почта';
      } else {
        this.error = '';
      }
    },

    onClose() {
      this.email = '';
      this.error = '';
      this.$emit('close');
    },
  },
  data() {
    return {
      staticClass: 'modal',
      email: '',
      error: '',
      isLoading: false,
      emailMatched: false,
    };
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          document.getElementById('email').focus();
        });
      }
    },
  },
};
</script>

<template>
  <div :class="[`${staticClass}-container`, showModal ? `${staticClass}-container--show` : '']">
    <transition name="fade">
      <div
        v-if="showModal"
        @click="!isLoading ? onClose() : ''"
        :class="`${staticClass}-overlay`"
      ></div>
    </transition>

    <transition name="fade">
      <div v-if="showModal" :class="staticClass">
        <section :class="`${staticClass}__head`">
          <h2 :class="`${staticClass}__title`">{{ currentLocalData.modalContact.title }}</h2>
          <p :class="`${staticClass}__note`">{{ currentLocalData.modalContact.desc }}</p>
        </section>
        <div :class="`${staticClass}__input`">
          <InputText
            @input="
              error = '';
              matchEmail();
            "
            @enter="send()"
            :id="'email'"
            v-model="email"
            :label-text="currentLocalData.modalContact.input.label"
            :error="error.length > 0"
            :help-text="error ? error : ' '"
          />
        </div>
        <div>
          <ButtonMain
            @click="send()"
            :disabled="error.length > 0 || !email.trim().length || $data.emailMatched"
            :text="currentLocalData.modalContact.submit"
            :loading="isLoading"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
