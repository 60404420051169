export default {
  header: {
    payment: 'Payment',
    docs: 'Docs',
    hosting: 'Hosting',
  },
  footer: {
    address:
      'Palliser House, Second Floor, Palliser Road, London, Greater London, United Kingdom, W14 9EB',
    status: 'Status',
    support: 'Support',
    docs: {
      termsOfService: {
        name: 'Terms of Service',
        link: '/assets/terms_of_service_en.pdf',
      },
      privacyPolicy: {
        name: 'Privacy Policy',
        link: '/assets/privacy_policy_en.pdf',
      },
    },
  },
  generalInfo: {
    currency: '$',
    provider: 'stripe'
  },
  pages: {
    hosting: {
      title: 'Hosting For Your Websites',
      desc: 'Choose a plan and start with a free trial period',
    },
    payment: {
      title: 'Payment Details',
      inputs: {
        login: {
          label: 'Login or domain',
        },
        amount: {
          label: 'Amount',
          helpText: '$5 or more',
        },
      },
      submit: 'Pay',
    },
    paymentResult: {
      success: {
        title: 'Payment successful',
        descr: 'The order has been paid.',
        details: {
          currency: '$',
          bonusDomains: 'for free',
          domainWord: ['domains', 'domain', 'domains'],
          sprintcoinsWord: ['sprintcoins', 'sprintcoin', 'sprintcoins'],
        }
      },
      fail: {
        title: 'Payment failed',
        descr: 'Please try again'
      },
      button: 'Back'
    }
  },
  modalContact: {
    title: 'Please enter your email',
    desc: 'We will contact you shortly',
    input: {
      label: 'Email',
    },
    submit: 'Send',
  },
  packages: {
    velvet: {
      subtitle: 'Plan',
      title: 'Soho',
      sitesCount: '2 websites',
      sitesDesc: 'On account',
      nvme: '3 GB',
      nvmeDesc: 'NVMe SSD',
      ram: '512 MB',
      ramDesc: 'RAM',
      cpu: '5 000 CPU',
      cpuDesc: 'Power',
      monthPrice: '$2',
      monthPriceDesc: 'per month',
      button: 'Order',
    },
    gold: {
      subtitle: 'Plan',
      title: 'West End',
      sitesCount: '5 websites',
      sitesDesc: 'On account',
      nvme: '6 GB',
      nvmeDesc: 'NVMe SSD',
      ram: '512 MB',
      ramDesc: 'RAM',
      cpu: '5 000 CPU',
      cpuDesc: 'Power',
      monthPrice: '$3.5',
      monthPriceDesc: 'per month',
      button: 'Order',
    },
    platinum: {
      subtitle: 'Plan',
      title: 'London',
      sitesCount: '10 websites',
      sitesDesc: 'On account',
      nvme: '15 GB',
      nvmeDesc: 'NVMe SSD',
      ram: '1 024 MB',
      ramDesc: 'RAM',
      cpu: '10 000 CPU',
      cpuDesc: 'Power',
      monthPrice: '$4.5',
      monthPriceDesc: 'per month',
      button: 'Order',
    },
  },
  errors: {
    default: 'An error occurred while processing the request',
  },
};
