<script>
export default {
  name: 'Globe',
};
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 0C4.48649 0 0 4.48649 0 10C0 15.5135 4.48649 20 10 20C15.5135 20 20 15.5135 20 10C20 4.48649 15.5135 0 10 0ZM6.31386 3.65306C5.65475 5.08113 5.23976 6.93641 5.1299 8.95036H1.68719C2.08995 5.74889 4.30975 3.10099 7.27638 2.07686C6.89349 2.54762 6.57248 3.09273 6.31386 3.65306ZM10 2.71322C9.585 2.71322 8.87707 3.11601 8.21796 4.54408C7.70532 5.66701 7.33915 7.20494 7.22929 8.95036H12.7707C12.6609 7.20494 12.3069 5.66701 11.782 4.54408C11.1229 3.11601 10.415 2.71322 10 2.71322ZM14.8701 8.95036C14.7602 6.93641 14.3452 5.09334 13.6861 3.65306C13.4275 3.09273 13.1065 2.54762 12.7236 2.07686C15.6902 3.10099 17.91 5.74889 18.3128 8.95036H14.8701ZM14.8701 11.0497C14.7602 13.0637 14.3452 14.919 13.6861 16.347C13.4276 16.9073 13.1066 17.4524 12.7237 17.9231C15.6903 16.8989 17.91 14.2511 18.3128 11.0497H14.8701ZM11.782 15.456C11.1229 16.8841 10.415 17.2869 10 17.2869C9.585 17.2869 8.87707 16.8841 8.21796 15.456C7.69311 14.3331 7.33915 12.7952 7.22929 11.0497H12.7585C12.6609 12.7952 12.3069 14.3331 11.782 15.456ZM6.31386 16.347C5.65475 14.9068 5.23976 13.0637 5.1299 11.0497H1.6872C2.08999 14.2511 4.30972 16.8989 7.27626 17.9231C6.89343 17.4524 6.57245 16.9073 6.31386 16.347Z"
      fill="#FF3200"
    />
  </svg>
</template>
