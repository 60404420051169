<script>
export default {
  name: 'LoadingElement',
};
</script>

<template>
  <svg viewBox="0 0 32 32" :width="20" :height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      id="spinner"
      cx="16"
      cy="16"
      r="14"
      class="ui4-circle-spinner"
      fill="none"
      :stroke="'white'"
    />
  </svg>
</template>

<style>
.ui4-circle-spinner {
  box-sizing: border-box;
  stroke-width: 4px !important;
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  animation: ui4-line 1.6s cubic-bezier(0.4, 0, 0.2, 1) infinite, ui4-rotate 1.6s linear infinite;
}

@keyframes ui4-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(450deg);
  }
}

@keyframes ui4-line {
  0% {
    stroke-dasharray: 2, 85.964;
    transform: rotate(0);
  }
  50% {
    stroke-dasharray: 65.973, 21.9911;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 2, 85.964;
    stroke-dashoffset: -65.973;
    transform: rotate(90deg);
  }
}
</style>
