import $axios from 'axios';

const BASE_URL = '/api/v1';

export const getPaymentLink = function (payload) {
  let fd = new FormData();
  fd.append('login', payload.login);
  fd.append('amount', payload.amount);
  fd.append('provider', payload.provider);

  let $result = {};
  let $error = {};

  return new Promise(resolve => {
    $axios
      .post(`${BASE_URL}/payment/link`, fd)
      .then(response => {
        $result = response;

        if (response.data.status === 1) return resolve($result.data);

        throw new Error();
      })
      .catch(e => {
        if (!e.response || !e.response.data) {
          $error.undefinedError = window.currentLocal.data.errors.default;
          return resolve($error);
        }

        $error = e.response.data.errors;
        return resolve($error);
      });
  });
};

export const sendEmail = function (data = { email: '', tariff: '' }) {
  let fd = new FormData();
  fd.append('email', data.email);
  fd.append('tariff', data.tariff);

  let $result = {};

  return new Promise((resolve, reject) => {
    $axios
      .post(`${BASE_URL}/send-email`, fd)
      .then(response => {
        $result = response;

        if (response.data.status === 1) return resolve($result.data);

        throw new Error();
      })
      .catch(e => {
        return resolve(window.currentLocal.data.errors.default);
      });
  });
};


export const getPaymentResultData = function(sessionId) {
  return new Promise((resolve, reject) => {
    $axios.post(`${BASE_URL}/payment/success`, {
      session_id: sessionId
    })
    .then((response)=> {
      if(response.data.data) return resolve(response.data.data);

      return resolve(0);
    })
  })
}
