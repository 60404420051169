<script>
import IconTemplate from '~/frontend/components/icons/IconTemplate.vue';
import ButtonMain from '~/frontend/components/Buttons/ButtonMain.vue';
export default {
  name: 'Card',
  components: {
    IconTemplate,
    ButtonMain,
  },
  props: {
    tariff: {
      type: Object,
      default() {
        return {
          title: '',
          sites: '',
          monthPrice: '',
        };
      },
    },
  },
  methods: {
    callbackClick() {
      this.$emit('openModal');
    },
  },
  data() {
    return {
      staticClass: 'package-card',
    };
  },
};
</script>

<template>
  <div :class="staticClass">
    <div :class="`${staticClass}__head`">
      <p :class="`${staticClass}__subtitle`">{{ tariff.subtitle }}</p>
      <p :class="`${staticClass}__title`">{{ tariff.title }}</p>
    </div>

    <div :class="`${staticClass}__list-wrap`">
      <div :class="`${staticClass}__list`">
        <div :class="`${staticClass}__item`">
          <IconTemplate :class="`${staticClass}__icon`" :icon-name="'Globe'" />
          <div :class="`${staticClass}__item-text`">
            <p :class="`${staticClass}__item-title`">{{ tariff.sitesCount }}</p>
            <p :class="`${staticClass}__item-note`">{{ tariff.sitesDesc }}</p>
          </div>
        </div>
        <div :class="`${staticClass}__item`">
          <IconTemplate :class="`${staticClass}__icon`" :icon-name="'NVMe'" />
          <div :class="`${staticClass}__item-text`">
            <p :class="`${staticClass}__item-title`">{{ tariff.nvme }}</p>
            <p :class="`${staticClass}__item-note`">{{ tariff.nvmeDesc }}</p>
          </div>
        </div>
        <div :class="`${staticClass}__item`">
          <IconTemplate :class="`${staticClass}__icon`" :icon-name="'Ram'" />
          <div :class="`${staticClass}__item-text`">
            <p :class="`${staticClass}__item-title`">{{ tariff.ram }}</p>
            <p :class="`${staticClass}__item-note`">{{ tariff.ramDesc }}</p>
          </div>
        </div>
        <div :class="`${staticClass}__item`">
          <IconTemplate :class="`${staticClass}__icon`" :icon-name="'Cpu'" />
          <div :class="`${staticClass}__item-text`">
            <p :class="`${staticClass}__item-title`">{{ tariff.cpu }}</p>
            <p :class="`${staticClass}__item-note`">{{ tariff.cpuDesc }}</p>
          </div>
        </div>
      </div>
    </div>

    <div :class="`${staticClass}__footer`">
      <div :class="`${staticClass}__price-wrap`">
        <span :class="`${staticClass}__price`">{{ tariff.monthPrice }}</span>
        {{ tariff.monthPriceDesc }}
      </div>
      <div :class="`${staticClass}__button`">
        <ButtonMain @click="callbackClick()" :text="tariff.button" />
      </div>
    </div>
  </div>
</template>
