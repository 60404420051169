<script>
export default {
  name: 'Ram',
};
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 7.62C19.55 7.62 20 7.17 20 6.62V4C20 2.9 19.1 2 18 2H2C0.9 2 0 2.9 0 4V6.62C0 7.17 0.45 7.62 1 7.62H2.5V8.39H1C0.45 8.39 0 8.84 0 9.39V14V18H2V16H3.6V18H5.6V16H7.2V18H9.2V16H10.8V18H12.8V16H14.4V18H16.4V16H18V18H20V14V9.39C20 8.84 19.55 8.39 19 8.39H17.5V7.62H19ZM17.5 10.39H18V14H2V10.39H2.5C3.6 10.39 4.5 9.49 4.5 8.39V7.62C4.5 6.52 3.6 5.62 2.5 5.62H2V4H18V5.62H17.5C16.4 5.62 15.5 6.52 15.5 7.62V8.39C15.5 9.49 16.4 10.39 17.5 10.39Z"
      fill="#060614"
      fill-opacity="0.96"
    />
  </svg>
</template>
