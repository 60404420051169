<script>
import LoadingElement from '~/frontend/components/Buttons/LoadingElement.vue';
export default {
  name: 'ButtonMain',
  components: {
    LoadingElement,
  },
  methods: {
    callbackClick(e) {
      this.focused = false;
      this.$emit('click');
    },
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'dark', // grey
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      staticClass: 'button-main',
      focused: false,
    };
  },
};
</script>

<template>
  <button
    :class="[staticClass, `${staticClass}--${color}`, { focused: focused }, { loading: loading }]"
    @click="callbackClick($event)"
    @keydown.space="callbackClick($event)"
    @keydown.enter="callbackClick($event)"
    @keydown.tab="focused = true"
    @blur="focused = false"
    :disabled="disabled"
  >
    <LoadingElement v-if="loading" />
    <template v-else>
      {{ text }}
    </template>
  </button>
</template>
