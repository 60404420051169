<script>
import InputText from '@dev-projects/uikit/components/v4/Fields/TextInputs/InputText.vue';
import ButtonMain from '~/frontend/components/Buttons/ButtonMain.vue';
import { getPaymentLink } from '~/frontend/actions';

export default {
  name: 'Payment',
  components: {
    InputText,
    ButtonMain,
  },
  props: {
    paymentData: {
      type: Object,
      default() {
        return {
          login: null,
          amount: null,
        };
      },
    },
  },
  computed: {
    currentLocalType() {
      return window.currentLocal.type;
    },
    currentLocalData() {
      return window.currentLocal.data;
    },
    currentPage() {
      return this.currentLocalData.pages.payment;
    },
    generalInfo() {
      return this.currentLocalData.generalInfo;
    },
    isDisabledButton: {
      get() {
        return !this.login.trim().length;
      },
    },
    isDisabledForm: {
      get() {
        return this.$route.query.login != undefined && this.$route.query.amount != undefined;
      },
    },
    isErrorLogin: {
      get() {
        return this.errors.login.length > 0;
      },
    },
    isErrorAmount: {
      get() {
        return this.errors.amount.length > 0;
      },
    },
    helpText: {
      get() {
        if(this.isErrorAmount) {
          return this.errors.amount;
        }

        if(!this.$route.query.amount) {
          return this.currentPage.inputs.amount.helpText;
        }

        return '';
      }
    }
  },
  methods: {
    sendPaymentData() {
      this.errors = {
        login: '',
        amount: '',
        undefinedError: '',
      };

      let data = {
        login: this.login,
        amount: this.amount.replace(this.generalInfo.currency, ''),
        provider: this.generalInfo.provider,
      };
      this.isLoading = true;

      getPaymentLink(data)
        .then(res => {
          if (res.status === 1) {
            if (!this.isDisabledForm) {
              this.login = '';
              this.amount = '';
            }

            return window.open(res.redirectUrl, '_self');
          }

          if (res.undefinedError) {
            this.errors.undefinedError = res.undefinedError;

            return this.errors;
          }

          for (let error in res) {
            this.errors[error] = res[error][0];
          }

          return this.errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onInput(input) {
      this.errors[input] = '';
      this.errors.undefinedError = '';
    },
  },
  mounted() {
    this.amount = this.$route.query.amount ? `${this.generalInfo.currency}${this.$route.query.amount}` : '';
  },
  data() {
    return {
      staticClass: 'payment',
      login: this.$route.query.login || '',
      isLoading: false,
      amount: '',
      errors: {
        login: '',
        amount: '',
        undefinedError: '',
      },
    };
  },
};
</script>

<template>
  <div :class="staticClass">
    <section :class="'head'">
      <h1 :class="'head__title'">{{ currentPage.title }}</h1>
      <img width="207" height="24" src="../images/pay-methods.svg" />
    </section>

    <div :class="`${staticClass}__form`">
      <div :class="`${staticClass}__input`">
        <InputText
          @input="onInput('login')"
          @enter="sendPaymentData()"
          v-model="login"
          :id="'login-input'"
          :label-text="currentPage.inputs.login.label"
          :help-text="isErrorLogin ? errors.login : ' '"
          :error="isErrorLogin"
          :disabled="isDisabledForm"
        />
      </div>

      <div :class="`${staticClass}__input`">
        <InputText
          @input="
            onInput('amount');
          "
          @enter="sendPaymentData()"
          v-model="amount"
          :id="'sum-input'"
          :label-text="currentPage.inputs.amount.label"
          :help-text="helpText"
          :error="isErrorAmount"
          :disabled="isDisabledForm"
        />
      </div>
    </div>

    <div :class="`${staticClass}__btn`">
      <span v-if="errors.undefinedError" :class="`${staticClass}__response-error`">
        {{ errors.undefinedError }}</span
      >
      <!-- В макете этого нет, просто на всякий случай. Заменить или убрать, если не надо-->
      <ButtonMain
        @click="sendPaymentData()"
        :disabled="isDisabledButton"
        :text="currentPage.submit"
        :loading="isLoading"
      />
    </div>
  </div>
</template>
