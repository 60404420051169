enum RouteNames {
  hosting = 'hosting',
  payment = 'payment',
  status = 'status',
}

enum Status {
  fail = 'fail',
  success = 'success'
}

export default {
  RouteNames,
  Status
}