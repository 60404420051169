

<script>
export default {
  name: 'Label',
  props: {
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'grey',
    }
  },
  data() {
    return {
      staticClass: 'label'
    }
  }
}
</script>
<template>
<div :class="[staticClass, `${staticClass}--${color}`]" v-html="text">

</div>
</template>