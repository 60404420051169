<script>
export default {
  name: 'Cpu',
};
</script>

<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 8.05V6.07H16.94V4.09C16.94 3.54 16.5 3.1 15.95 3.1H13.97V1H11.99V3.1H8.02V1H6.04V3.1H4.06C3.51 3.1 3.07 3.54 3.07 4.09V6.07H1V8.05H3.05V12.01H1V13.99H3.05V15.97C3.05 16.52 3.49 16.96 4.04 16.96H6.02V19H8V16.95H11.97V19H13.95V16.95H15.93C16.48 16.95 16.92 16.51 16.92 15.96V13.98H19V12H16.94V8.04H19V8.05ZM14.96 14.97H5.04V5.07H14.96V14.97Z"
      fill="#060614"
      fill-opacity="0.96"
    />
  </svg>
</template>
