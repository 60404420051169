export default {
  velvet: {
    name: 'velvet',
    title: 'Бархатный',
    sites: '2 сайта',
    nvme: '3 ГБ',
    ram: '512 МБ',
    cpu: '5 000 CPU',
    monthPrice: '1 500 ₸',
  },
  gold: {
    name: 'gold',
    title: 'Золотой',
    sites: '5 сайтов',
    nvme: '6 ГБ',
    ram: '512 МБ',
    cpu: '5 000 CPU',
    monthPrice: '1 950 ₸',
  },
  platinum: {
    name: 'platinum',
    title: 'Платиновый',
    sites: '10 сайтов',
    nvme: '15 ГБ',
    ram: '1 024 МБ',
    cpu: '10 000 CPU',
    monthPrice: '2 500 ₸',
  },
};
