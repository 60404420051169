<script>
export default {
  name: 'OpenLink',
};
</script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99999 3.9999H3.99999C3.11634 3.9999 2.39999 4.71625 2.39999 5.5999V11.9999C2.39999 12.8836 3.11634 13.5999 3.99999 13.5999H12C12.8836 13.5999 13.6 12.8836 13.6 11.9999V9.5999M15.2 2.3999L8.79999 8.7999M15.2 2.3999V6.3999M15.2 2.3999H11.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
