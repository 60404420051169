<script>
import Cpu from '~/frontend/components/icons/src/Cpu.vue';
import Globe from '~/frontend/components/icons/src/Globe.vue';
import NVMe from '~/frontend/components/icons/src/NVMe.vue';
import Ram from '~/frontend/components/icons/src/Ram.vue';
import OpenLink from '~/frontend/components/icons/src/OpenLink.vue';
import StatusOk from './src/StatusOk.vue';
import StatusFail from './src/StatusFail.vue';
export default {
  name: 'IconTemplate',
  components: {
    Cpu,
    Globe,
    NVMe,
    Ram,
    OpenLink,
    StatusOk,
    StatusFail
  },
  props: {
    iconName: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div v-if="Object.keys($options.components).includes($props.iconName)">
    <component :is="iconName"></component>
  </div>
</template>
